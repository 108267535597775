import Head from 'next/head';
import { Config } from '../src/core/utils/Config';
import '../../styles/app.scss';

export default function App({ Component, pageProps }) {

  return (
    <>
      {
        !Config.IS_STATIC_EXPORT && (
          <Head>
            <meta name="viewport" content="width=device-width, initial-scale=1" />
          </Head>
        )
      }
      <Component {...pageProps} />
    </>
  )
}