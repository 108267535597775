module.exports = {
  REQUIRED_STRINGS: [
    '%s1 results for %s2 filtered by %s3',
    '%s1 – %s2 of %s3 items',
    'API',
    'Accessibility',
    'Aerospace and Defense',
    'All',
    'Analytics',
    'Analytics,Cloud,Watson Platform',
    'Application',
    'Applies to version',
    'Apply',
    'Are you a customer for "%s"?',
    'Are you looking for developer resources for "%s"?',
    'Are you looking for information about "%s"?',
    'Are you looking for product information about "%s"?',
    'Are you looking for support information about "%s"?',
    'Articles',
    'Automotive',
    'Badge',
    'Banking/Financial Services',
    'Based on your search, IBM\'s sale of a group of products (including "%s") might affect you.',
    'Blog',
    'Brands',
    'Browse products by popular topics',
    'Business needs',
    'By topic',
    'By type',
    'By category',
    'Call IBM',
    'Certification and badges',
    'Chat with us now',
    'Check all results',
    'Chemicals and Petroleum',
    'Clear all',
    'Clear all filters',
    'Clear filter',
    'Clear filters',
    'Click here for more information on that error code.',
    'Click here to find your career opportunity at IBM Careers',
    'Click here to visit IBM Docs for more information.',
    'Close',
    'Cloud',
    'Cloud Provider',
    'Cloud computing',
    'Cloud documentation',
    'Collaboration',
    'Commerce',
    'Communications',
    'Community content and code - IBM Developer',
    'Construction/Agriculture',
    'Consumer Products',
    'Contact',
    'Course',
    'Cross Industry',
    'Data Set',
    'Deployment model',
    'Deployment types',
    'Developer forums',
    'Documentation',
    'Documentation for hardware and on-prem software',
    'Documentation for IBM Cloud products',
    'Documentation from IBM Cloud',
    'Documentation from IBM Knowledge Center',
    'Download fixes, updates, firmware, security patches, and drivers from IBM Fix Central',
    'Download products',
    'Downloads',
    'Education',
    'Electronics',
    'Email a sales rep',
    'Email an expert',
    'Energy and Utilities',
    'Explore career opportunities',
    'Explore the catalog',
    'Featured products',
    'Filters',
    'Filter by',
    'Find a product',
    'Find all your CVEs on the Product Security Central site.',
    'Find product fixes',
    'Find training',
    'Find your part in the IBM Maintenance Parts Store',
    'Fixes',
    'For developers',
    'Found %s1 search results in IBM %s2.',
    'Free trial',
    'Free trials and editions',
    'Free trials and community editions',
    'Get product help',
    'Government',
    'Hardware',
    'Healthcare/Life Sciences',
    'Hospitality',
    'How can we help?',
    'Human Resources',
    'IBM Announcement letters',
    'IBM Documentation',
    'IBM Fix Central is the common location for downloading updates to many IBM products for clients that have service agreements and entitlements.',
    'IBM Products',
    'IBM Search',
    'IBM and other sellers',
    'IBM and other vendors',
    'IBM z entitled PTFs visit IBM Shopz.',
    'IBM Z Software',
    'IT management',
    'Industry',
    'Industries',
    'Infrastructure',
    'Insurance',
    'Items per page',
    'Journey',
    'Kubernetes platforms from IBM',
    'Known Issues (APARs)',
    'Learn more',
    'Learn more about the APAR in IBM Support',
    'Learn more about the IBM divestiture',
    'Learn more about the Known Issue in IBM Support.',
    'Learn more about the PTF in IBM Support',
    'Learning',
    'Learning type',
    'Legal',
    'Log in to IBM Cloud to view your case',
    'Log in to IBM Support to view your case',
    'Log in to Passport Advantage Online',
    'Look at our product lines',
    'Looking for an IBM Part number %s?',
    'Looking for help with DB2 SQL error messages?',
    'Looking for Common Vulnerabilities and Exposures: %s?',
    'Looking for IBM Content Navigator error code %s?',
    'Looking for information for IBM Storwize part # %s?',
    'Looking for Known Issue %s?',
    'Looking for Lenovo part # %s?',
    'Looking for your support case %s?',
    'Looking to download IBM eAssembly or parts %s?',
    'Manufacturing',
    'Marketing',
    'Marketing and sales',
    'Media and Entertainment',
    'Metal and Mining',
    'Mobile',
    'Need help?',
    'Next',
    'No results found',
    'On-Premises',
    'Other IBM PTFs visit IBM Support.',
    'Other sellers',
    'Other vendors',
    'Our search function is temporarily unavailable.',
    'Packaged as an IBM Cloud Pak',
    'Please wait a few minutes and try again. We apologize for any inconvenience this may have caused you.',
    'Podcast',
    'Previous',
    'Priority code:',
    'Privacy',
    'Procurement/Distribution',
    'Product documentation - Knowledge Center',
    'Product types',
    'Products & services',
    'Products & Solutions home',
    'Products and Services',
    'Products with popular trials',
    'Products',
    'Questions and answers',
    'Real Estate',
    'Release date',
    'Results per page',
    'Retail',
    'SDKs and other toolkits',
    'Search tips',
    'Search',
    'Search by product name',
    'Security',
    'See all top downloads',
    'See our FAQ',
    'See our products for your large or small enterprise solutions',
    'Select filters',
    'Service',
    'Services Assets',
    'Shop for the best deals',
    'Show matches for',
    'Show more popular trials',
    'Showing %s products by popularity:',
    'Showing 1 product:',
    'Site map',
    'Software',
    'Special offer',
    'Still need help?',
    'Strategic insights',
    'Support & documentation',
    'Support and documentation',
    'Support forums',
    'Supports IBM Cloud Private',
    'Supports IBM Cloud Kubernetes Service',
    'Supports IBM Cloud Kubernetes Services',
    'Systems',
    'Security bulletins',
    'Talk to an expert',
    'Technical books - Redbooks',
    'Technical guides',
    'Technical guides - Redbooks',
    'Technical guides/Redbooks',
    'Technologies',
    'Telecommunications',
    'Terms of use',
    'The place to shop for software, hardware and services from IBM and our providers. Browse by technologies, business needs and services.',
    'To obtain this part, open an IBM support case here.',
    'Top downloads based on your query:',
    'Top products & services',
    'Topics',
    'Top searches on ibm.com',
    'Training and courses - Skills Gateway',
    'Travel and Transportation',
    'Troubleshooting articles',
    'Tutorials',
    'Types',
    'Vendors',
    'View all',
    'View all products',
    'View less',
    'View more',
    'View',
    'Video',
    'Visit our Support Portal, where you can find solutions for issues with your IBM products',
    'Visit the Lenovo Parts Lookup here.',
    'You might have mis-typed or your words are too broad or too specific. Try using a broader phrase or look at our product lines',
    'Research products',
    'result',
    'results',
    'results for',
    `Search couldn't find any matches`,
    `Search couldn't find any matches for`,
    `Search couldn't find any matches in “%s”`,
    'Search for “%s” in All of IBM.com instead?',
    'Search in All of IBM.com instead?',
    'result for',
    'Fixes and troubleshooting articles',
    'IBM account',
    'Log in to IBM Support to view your case',
    'Log in to The IBM Service Request (SR) application to view your case',
    'Looking for an APAR (Authorized Program Analysis Report) %s?',
    'Looking for fixes, updates or information for your system’s software, hardware, and operating system?',
    'Looking for fixes, updates or information for your system’s software, hardware, and operating system? Find them on %s',
    'Looking for IBM image number %s?',
    'Looking for PTF (Program Temporary Fix) %s?',
    'Looking for the free trials you’ve signed up for or products you’ve purchased? Find them in your %s',
    'Looking for the free trials you’ve signed up for or products you’ve purchased?',
    'Looking for work? Req ID# %s',
    'Looking for your Cloud support case %s?',
    'Looking for your IBM Service Request (previously called PMR) [%s]?',
    'Looking for your Passport Advantage software licenses, SaaS offerings or subscriptions? Find them on %s',
    'Looking for your Passport Advantage software licenses, SaaS offerings or subscriptions?',
    'Looking for your support case %s?',
    'Find them on %s',
    'My IBM trials and products',
    'Passport Advantage',
    'Watson Customer Engagement',
    'Watson Platform',
    'What are you looking for today?',
    'Find them in your %s',
    '%s Reviews',
    '1 Review',
    'Need Product help?',
    'Visit IBM Support'
  ],
  SUPPORTED_LOCALES: [
    'ae-ar',
    'ae-en',
    'af-en',
    'ag-en',
    'ai-en',
    'ao-pt',
    'ar-es',
    'at-de',
    'au-en',
    'aw-en',
    'bb-en',
    'bd-en',
    'be-en',
    // Remove after May 25th new language support
    'be-fr',
    'bf-fr',
    'bg-en',
    'bh-en',
    'bm-en',
    'bn-en',
    'bo-es',
    'br-pt',
    'bs-en',
    'bw-en',
    'ca-en',
    'ca-fr',
    'cd-fr',
    'cg-fr',
    'ch-de',
    'ch-fr',
    'ci-fr',
    'cl-es',
    'cm-fr',
    'cn-zh',
    'co-es',
    'cr-es',
    'cw-en',
    'cz-en',
    'de-de',
    'dk-en',
    'dm-en',
    'dz-fr',
    'ec-es',
    'ee-en',
    'eg-en',
    'es-es',
    'et-en',
    'fi-en',
    'fr-fr',
    'ga-fr',
    'gd-en',
    'gh-en',
    'gr-en',
    'gy-en',
    'hk-en',
    'hr-en',
    'hu-en',
    'id-en',
    'ie-en',
    'il-en',
    'in-en',
    'iq-en',
    'it-it',
    'jm-en',
    'jo-en',
    'jp-ja',
    'ke-en',
    'kh-en',
    'kn-en',
    'kr-ko',
    'kw-en',
    'ky-en',
    'kz-en',
    'lb-en',
    'lc-en',
    'lk-en',
    'lt-en',
    'lv-en',
    'ly-en',
    'ma-fr',
    'mg-fr',
    'ms-en',
    'mu-fr',
    'mw-en',
    'mx-es',
    'my-en',
    'mz-pt',
    'na-en',
    'ne-fr',
    'ng-en',
    'nl-en',
    'no-en',
    'np-en',
    'nz-en',
    'om-en',
    'pe-es',
    'ph-en',
    'pk-en',
    'pl-pl',
    'pt-pt',
    'py-es',
    'qa-en',
    'ro-en',
    'rs-en',
    'ru-ru',
    'sa-ar',
    'sa-en',
    'sc-fr',
    'se-en',
    'sg-en',
    'si-en',
    'sk-en',
    'sl-en',
    'sn-fr',
    'sr-en',
    'tc-en',
    'td-fr',
    'th-en',
    'tn-fr',
    'tr-tr',
    'tt-en',
    'tw-zh',
    'tz-en',
    'ua-en',
    'ug-en',
    'uk-en',
    'us-en',
    'uy-es',
    'uz-en',
    'vc-en',
    've-es',
    'vg-en',
    'vn-en',
    'ye-en',
    'za-en',
    'zm-en',
    'zw-en'
  ],
  SEARCHKIT_TRANSLATION_OVERRIDES: {
    'searchbox.placeholder': 'Search',
    'pagination.next': 'Next',
    'pagination.previous': 'Previous',
    'facets.view_all': 'View all',
    'facets.view_less': 'View less',
    'facets.view_more': 'View more',
    'reset.clear_all': 'Clear all filters',
    'Cross-Industry': 'Cross Industry'
  },
  EXCEPTION_LANGUAGES: [
    'es-ar',
    'es-mx',
    'es-cl',
    'es-co',
    'es-pe',
    'fr-ca',
    'pt-br',
    'zh-cn',
    'zh-tw'
  ],
  getTranslatedStringsMapping (locale) {
    const seperatedString = locale.split('-');

    return `${seperatedString[0]}_${(seperatedString[1].toUpperCase())}`;
  },
  getMasterLanguageForTranslations: function (cc, lc) {
    let masterLanguage, upperCaseCC;

    if (cc !== 'es' && lc === 'es') {
      masterLanguage = 'esLa';
    } else if (lc === 'es') {
      masterLanguage = 'es';
    } else if (lc === 'zh') {
      upperCaseCC = cc.charAt(0).toUpperCase() + cc.slice(1);
      masterLanguage = `${lc}${upperCaseCC}`;
    } else {
      // if language exists, use matching language json
      masterLanguage = lc;
    }

    return masterLanguage;
  },
  getDefaultLangCodeForLocale: function (locale, lc) {
    const exceptionMap = this.EXCEPTION_LANGUAGES;

    if (exceptionMap.includes(locale)) {
      return this.getTranslatedStringsMapping(locale);
    } else if (lc === 'es') {
      return this.getTranslatedStringsMapping('es-es');
    } else if (lc === 'fr') {
      return this.getTranslatedStringsMapping('fr-fr');
    } else if (lc === 'pt') {
      return this.getTranslatedStringsMapping('pt-pt');
    } else if (lc === 'de') {
      return this.getTranslatedStringsMapping('de-de');
    } else if (lc === 'en') {
      return this.getTranslatedStringsMapping('en-us');
    }
    return this.getTranslatedStringsMapping(locale);
  },
  getTranslationForLocale: function (cc, lc) {
    const locale = `${lc}-${cc.toLowerCase()}`,
      translationForLocale = this.getDefaultLangCodeForLocale(locale, lc);

    return translationForLocale;
  }
};
